
import request from '../utils/request';
//获取证书
export function getCertDetail(data) {
  return request({
    url: '/userData/getCertDetail.html',
    method: 'post',
    data
  });
}

//获取二维码
export function toGeneratingEwm2(data) {
  return request({
    url: '/order/toGeneratingEwm2.html',
    method: 'post',
    data
  });
}

//获取可申请证书列表
export function toApplyCert(data) {
  return request({
    url: '/userData/toApplyCert.do',
    method: 'post',
    data
  });
}

//搜索代领
export function toSearchByCardNo(data) {
  return request({
    url: '/userData/toSearchByCardNo.do',
    method: 'post',
    data
  });
}

//获取地址
export function getUserAddressList(data) {
  return request({
    url: '/userData/getUserAddressList.do',
    method: 'post',
    data
  });
}

//获取安徽省地址
export function getAllAreaList(data) {
  return request({
    url: '/web/getAllAreaList.html',
    method: 'post',
    data
  });
}

//添加地址
export function addUserAddress(data) {
  return request({
    url: '/userData/addUserAddress.do',
    method: 'post',
    data
  });
}


//修改地址
export function updateUserAddress(data) {
  return request({
    url: '/userData/updateUserAddress.do',
    method: 'post',
    data
  });
}

//缴纳运费
export function toGenExpress(data) {
  return request({
    url: '/userData/toGenExpress.do',
    method: 'post',
    data
  });
}

//取消运单
export function toCancelExpress(data) {
  return request({
    url: '/userData/toCancelExpress.do',
    method: 'post',
    data
  });
}

//获取运单详情
export function getExpressDetail(data) {
  return request({
    url: '/userData/getExpressDetail.do',
    method: 'post',
    data
  });
}


