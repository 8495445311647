<template>
  <div>
    <div id="main"
         class="main zhengShuBanner">

      <div class="zs"
           id="zs">
        <div style="display: flex; justify-content: center;position: absolute;right: -225px;"
             v-if="doPrintw">
          <div>
            <el-button type="primary"
                       @click="doPrint">打印证书</el-button>

            <el-button type="primary"
                       v-show="!certFlag"
                       @click="flags">保存图片</el-button>
            <el-button type="primary"
                       v-show="certFlag"> <a type="button"
                 id="down"
                 href=""
                 download="downImg">保存图片</a></el-button>
          </div>
        </div>
        <img src="../../../assets/images/zs-02.jpg"
             alt="背景色"
             class="bg" />

        <img v-if="certInfo.yearFlag == '01'"
             src="../../../assets/images/huanan.png"
             alt="章"
             class="zs_bone_left" />

        <img src="../../../assets/images/zhang.png"
             alt="章"
             class="zs_bone" />
        <div style="position: relative; z-index: 222">
          <p class="zs_num">学号 : <span style="font-size: 12px">{{ certInfo.studyNo || '' }}</span></p>
          <h1>安徽省专业技术人员继续教育登记表</h1>

          <table class="table1">
            <tr>
              <td class="td1"
                  style="width: 94px; height: 42px">姓&emsp;&emsp;名</td>
              <td class="td1"
                  style="width: 106px">{{ certInfo.realname || '' }}</td>
              <td class="td1"
                  style="width: 104px">身份证号</td>
              <td class="td2"
                  style="width: 227px">{{ certInfo.cardno || '' }}</td>

              <td rowspan="3"
                  class="td2"><img :src="certInfo.userPhoto"
                     alt="头像"
                     style="width: 90px; height: 128px; margin-top: 1px" />

              </td>
            </tr>
            <tr>
              <td class="td1"
                  style="width: 94px">性&emsp;&emsp;别</td>
              <td class="td1"
                  style="width: 106px">
                {{ certInfo.sex == '01' ? '男' : '女' }}
                <!-- 女 -->
              </td>
              <td class="td1"
                  style="width: 104px">工作单位</td>
              <td class="td2"
                  style="width: 227px">
                <p>{{ certInfo.organization || '' }}</p>
              </td>
            </tr>
            <tr>
              <td colspan="5"
                  style="height: 36px"><span class="zs_qk">接 受 继 续 教 育 情 况</span></td>
            </tr>
          </table>

          <ul>
            <li style="width: 202px; height: 30px; line-height: 30px"
                class="li1">继续教育项目名称</li>
            <li style="width: 423px; height: 30px; line-height: 30px"
                class="li2">{{ certInfo.className || '' }}</li>
            <li style="width: 94px; height: 30px; line-height: 30px"
                class="li1">主办单位</li>
            <li style="width: 210px; height: 30px; line-height: 30px"
                class="li1">安徽冠成教育科技有限公司</li>
            <li style="width: 94px; height: 30px; line-height: 30px"
                class="li1">学习形式</li>
            <li style="width: 227px; height: 30px; line-height: 30px"
                class="li2">在线学习</li>
            <li style="width: 94px; height: 30px; line-height: 30px"
                class="li1">培训时间</li>
            <li style="width: 210px; height: 30px; line-height: 30px"
                class="li1">{{ certInfo.startYear || '' }}年{{ certInfo.startMonth || '' }}月-{{ certInfo.endYear || '' }}年{{ certInfo.endMonth || '' }}月</li>
            <li style="width: 94px; height: 30px; line-height: 30px"
                class="li1">总学时数</li>
            <li style="width: 227px; height: 30px; line-height: 30px"
                class="li2">{{ Math.ceil(certInfo.creditSum) || '' }}</li>
          </ul>

          <table class="table2">
            <div style="display: flex;">
              <div style="width: 210px"
                   class="td1 border1">学习科目</div>
              <div style="width: 37px"
                   class="td1 border1">学时</div>
              <div style="width: 66px"
                   class="td1 border1">评定结果</div>
              <div style="width: 210px"
                   class="td1 border1">学习科目</div>
              <div style="width: 37px"
                   class="td1 border1">学时</div>
              <div style="width: 66px;border-right: 0;"
                   class="td2 border1">评定结果</div>
            </div>
            <div style="display: flex;">
              <div style="display: flex;flex-wrap: wrap;">
                <div style="display: flex;flex-wrap: wrap;"
                     v-for="(item,index) in certInfo.creditList"
                     :key="index"
                     v-show="index%2 == 0">
                  <div style="width: 210px"
                       class="td1 border1">{{ item.courseName || ''}}</div>
                  <div style="width: 37px"
                       class="td1 border1">{{ Math.ceil(item.credit) || '' }}</div>
                  <div style="width: 66px;"
                       class="td1 border1">合格</div>
                </div>
                <div style="display: flex;"
                     v-for="(item,index) in certInfo.creditList"
                     :key="index+50"
                     v-show="index%2 !== 0">
                  <div style="width: 210px"
                       class="td1 border1">{{ item.courseName || ''}}</div>
                  <div style="width: 37px"
                       class="td1 border1">{{ item.credit || ''}}</div>
                  <div style="width: 66px;"
                       class="td1 border1">合格</div>

                </div>
              </div>

            </div>
          </table>
        </div>
        <p class="zs_z">培训单位（盖章）</p>

        <p class="zs_date"><span>{{ certInfo.endYear || '' }}</span>年<span>{{ certInfo.endMonth || '' }}</span>月<span>{{ certInfo.endDay || '' }}</span>日</p>

        <div id="ewm">
          <img :src="ewm"
               alt="二维码"
               width="70"
               height="70"
               class="left" />
          <p class="left" style="margin-left: 10px;">扫描<br />查验<br />证书</p>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import html2canvas from 'html2canvas';
import { getCertDetail, toGeneratingEwm2 } from '@/api/cert.js';
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    // 这里存放数据
    return {
      certInfo: {}, //证书数据
      ewm: '',
      doPrintw: true,
      certFlag: false
    };
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    flags() {
      this.$message('证书正在生成中，请等待5秒后再点击！');
      
    },
    //获取二维码
    toGeneratingEwm2() {
      toGeneratingEwm2({ uri: window.location.href }).then(res => {
        console.log(res.data.data.data);
        this.ewm = res.data.data.data;
      });
    },
    est() {
      var canvas2 = document.createElement('canvas'); //创建一个新的canvas
      let _canvas = document.querySelector('.zhengShuBanner'); //这里面填写 你需要截图的div
      var w = parseInt(window.getComputedStyle(_canvas).width);
      var h = parseInt(window.getComputedStyle(_canvas).height);
      var ctx = canvas2.getContext('2d');
      canvas2.width = w * 2;
      canvas2.height = h * 2; //将canvas画布放大2倍或者更多，然后盛放在较小的容器内，就显得不模糊了
      canvas2.style.width = w + 'px';
      canvas2.style.height = h + 'px';
      var context = canvas2.getContext('2d');
      context.scale(2, 2);
      html2canvas(document.querySelector('#zs'), {
        //写需要截图的div
        scale: 2,
        // allowTaint: false,
        useCORS: true
      }).then(function (canvas) {
        document.querySelector('#down').setAttribute('href', canvas.toDataURL('image/jpg')); //down设置为你的点击键
      });
      // toTop();
    },
    doPrint() {
      this.doPrintw = false;
      setTimeout(() => {
        window.print();
      }, 100);

      setTimeout(() => {
        this.doPrintw = true;
      }, 2000);
    },
    getCertDetail() {
      getCertDetail({ certId: this.$route.query.id }).then(res => {
        this.certInfo = res.data.data;
        setTimeout(() => {
          this.certFlag = true;
        }, 5000);
      });
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getCertDetail();
    this.toGeneratingEwm2();
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    setTimeout(() => {
      this.est();
    }, 5000);
  },
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {} // 生命周期 - 挂载之前
};
</script>
<style lang="less" scoped>
/* //@import url(); 引入公共css类 */
a {
  text-decoration: none;
  color: #fff;
}
table {
  border-spacing: 0;
  border-collapse: collapse;
}
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.zs {
  font-size: 12px;
  color: #000000;
  width: 751px;
  height: 1061px;
  margin: 0 auto;
  padding: 117px 67px 64px 61px;
  position: relative;
}
.zs .bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}
.zs .zs_bone {
  position: absolute;
  bottom: 65px;
  right: 115px;
}
.zs_bone_left {
  position: absolute;
  bottom: 65px;
  right: 320px;
}
.zs .zs_num {
  padding-left: 400px;
}
.zs h1 {
  margin-top: 15px;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
}
.zs .table1 {
  width: 625px;
  border: 2px solid #b2b2b2;
  border-left: none;
  border-right: none;
  margin-top: 20px;
}
.zs .table1 tr td {
  text-align: center;
}
.zs .table1 tr td .zs_qk {
  font-size: 20px;
}
.zs .table1 tr td p {
  text-align: left;
  padding-left: 10px;
}
.zs .table1 tr .td1 {
  border-right: 2px solid #b2b2b2;
  border-bottom: 2px solid #b2b2b2;
}

.border1 {
  border-right: 2px solid #b2b2b2;
  border-bottom: 2px solid #b2b2b2;
  text-align: center;
  min-height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  overflow-wrap: break-word;
  // line-height: 30px;
}
.zs .table1 tr .td2 {
  border-bottom: 2px solid #b2b2b2;
}
.zs ul {
  width: 625px;
  overflow: hidden;
}
.zs ul li {
  float: left;
  text-align: center;
}
.zs ul .li1 {
  border-right: 2px solid #b2b2b2;
  border-bottom: 2px solid #b2b2b2;
}
.zs ul .li2 {
  border-bottom: 2px solid #b2b2b2;
}
.zs .table2 {
  font-size: 12px;
}
.zs .table2 tr td {
  text-align: center;
  padding: 8px 0;
}
.zs .table2 tr td p {
  padding: 0 12px;
  text-align: left;
}
.zs .table2 tr .td1 {
  border-right: 2px solid #b2b2b2;
  border-bottom: 2px solid #b2b2b2;
}
.zs .table2 tr .td2 {
  border-bottom: 2px solid #b2b2b2;
}
.zs .zs_z {
  padding-left: 300px;
  margin-top: 20px;
  position: absolute;
  top: 910px;
  left: 169px;
}
.zs .zs_date {
  padding-left: 325px;
  margin-top: 20px;
  position: absolute;
  top: 950px;
  left: 180px;
}
.zs .zs_date span {
  display: inline-block;
  margin: 0 7px;
}
#ewm {
  position: absolute;
  bottom: 63px;
  display: flex;
  left: 100px;
  padding: 0 10px;
}
</style>
